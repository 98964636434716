<template>
<div id="policy" class="marg-big container-fluid" style='font-size:1.1rem'>
    <!--breadcrumb-->
    <nav class="navbar navbar-light bg-light rounded" aria-label="breadcrumb">
        <div class="container-fluid">
            <ol class="breadcrumb">
                <li class="breadcrumb-item nav-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active nav-item"><a href="#">Policy</a></li>
            </ol>
        </div>
    </nav>
    <!--headline-->
    <section class="marg-big">
        <h2>Private Policy</h2>
        <br />
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
            The following explains Koohi.cafe's policies on data gathering, privacy, and data usage. Account creation and use of the website implies knowledge of and consent of the following policies.
        </div>
    </section>
    <section class="marg-big">
        <h2>Identifying Information: Collection & Usage</h2>
        <br />
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
            When signing up for an account, several identifiers are used for verifying account ownership. These include an email, password, and username.<br /><br />Emails are used as an identifier through which the admin or automated systems within the website may contact the user. Emails may be sent if the admin feels there is reason to contact a user. Usernames are used as an identifier for user activities on the database and may be seen by the admin or other users.
            <br /><br />
            Please use non-identifying information (such as a unique email and username) if uncomfortable with having identifying information on the website.
            <br /><br />
            Personal information such as age, geolocation, or payment information are not collected by Koohi. Third party scripts may collect this when relevant. See below.
        </div>
    </section>
    <section class="marg-big">
        <h2>Use of Third Party Scripts</h2>
        <br />
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
            Websites often use third-party scripts such as Google Analytics to assist in monitoring website usage. These scripts may track user information without explicit user or admin knowledge. Please refer to the third party's private policy guidelines for more information.<br /><br />The use of script blocking browser extensions are permitted and actively encouraged for users worried about their privacy. Note that script blocking may have adverse effects on the website, such as blocking critical code needed to run certain parts of the website. While script blocking is encouraged, technical support for doing so will not be handled by the admin.
        </div>
    </section>
    <section class="marg-big">
        <h2>Account Management</h2>
        <br />
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
            The admin reserves the right to modify any information for an account that involves website activity. This includes the right to delete, change, or add new information associated with an account.
            <br /><br />
            In addition, the admin reserves the ability to ban accounts as well as the ability to add or remove website-related privilleges gained from transactions or other such events. Reasons for doing so include but are not limited to: inappropriate conduct on the website or relating to the website, use of unintended loopholes or workarounds ("hacking"), cheating, spamming, etc.
        </div>
    </section>
    <section class="marg-big">
        <h2>Private Policy Updates</h2>
        <br />
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
            Users will be notified through the in-website notification system or through another system the admin deems appropriate when private policies are modified.
        </div>
    </section>
    <section class="marg-big">
        <h2>User Privilleges</h2>
        <br />
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
            Users retain the right to contact the admin through approved channels to change or remove information stored on the website that is associated with the user.
            <br /><br />
            Approved channels include:
            <ul>
                <li><a href="https://community.wanikani.com/t/koohicafe-aka-floflo-a-wk-friendly-srs/48703" style="text-decoration:underline;">Wanikani</a> (include @raionus)</li>
                <li>Discord App by adding raionus#8576</li>
            </ul>
        </div>
    </section>
</div>
</template>

<script>
export default {
    name: 'Policy',
    data() {
        return {}
    },
    methods: {
        register() {
            this.$emit('setup-register')
        }
    }
}
</script>
